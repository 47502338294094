import React from 'react'
import "./CardComponent.css"

const CardComponent = props => (
    <div className = "CardComponent">
        <div className = "LeftCardGroup"> 
            <div className = {props.imageClassName}>
                <img src={props.img}></img>
            </div>
        </div>
        <div className = "RightCardGroup">
            <div className = "RightCardGroupTextContainer">
                <h1>{props.title}</h1>
                <h2>Amount Saved</h2>
            </div> 
        </div>
    </div>
)

export default CardComponent