import React from 'react'
import './index.css'
import Link from 'gatsby-link'
import ChatBubble from '../components/ChatBubble'
import LineItem from '../components/LineItem'
import CardComponent from '../components/CardComponent'
import getPlayStoreUrl from '../getPlayStoreUrl'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import Footer from './Footer'

class FrontPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMobile: false,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }

  handleResize = () => {
    const innerWidth = window.innerWidth
    const isMobile = window.innerWidth <= 640

    if (this.state.isMobile != isMobile) {
      this.setState({ isMobile: isMobile })
    }
  }

  androidClicked = () => {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: 'Button',
      // string - required - Type of interaction (e.g. 'play')
      action: 'Click',
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: 'Android',
    })
    window.location.href = getPlayStoreUrl()
  }

  appleClicked = () => {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: 'Button',
      // string - required - Type of interaction (e.g. 'play')
      action: 'Click',
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: 'iOS',
    })
    window.location.href =
      'https://itunes.apple.com/us/app/clerkie-money-advisor-finance/id1132917036?mt=8'
  }

  buttonTapped = () => {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: 'Button',
      // string - required - Type of interaction (e.g. 'play')
      action: 'Click',
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: 'Sign Up',
    })
    window.location.href = 'https://app.clerkie.io'
  }

  render() {
    let heroText
    let loansHeader
    let debtGroup

    if (this.state.isMobile) {
      heroText = (
        <div className="HeroRightGroup">
          <h1>Let's solve your debt + money problems</h1>
        </div>
      )
    } else {
      heroText = (
        <div className="HeroRightGroup">
          <h1>
            Let's solve your debt<br></br>and money problems
          </h1>
          <h3>
            The easiest way to pay off your debt and solve all your financial
            headaches
          </h3>
          <p1 className="HeroButton" onClick={event => this.buttonTapped()}>
            Sign Up
          </p1>
        </div>
      )
    }

    if (this.state.isMobile) {
      loansHeader = (
        <h1>
          Clerkie always got<br></br>your back
        </h1>
      )
    } else {
      loansHeader = (
        <h1>
          Clerkie always got<br></br>your back
        </h1>
      )
    }

    if (this.state.isMobile) {
      debtGroup = (
        <div>
          <h1>Crush your debt</h1>
          <h3>
            Negotiate and lower<br></br>your debt by up to 70%
          </h3>
          <div className="DebtCards">
            <div className="DebtCard1">
              <CardComponent
                imageClassName="CardImage funPink"
                img={require('../images/student_loans_icon.png')}
                title="$40,558"
              />
            </div>
            <div className="DebtCard2">
              <CardComponent
                imageClassName="CardImage funLightBlue"
                img={require('../images/loans_icon.png')}
                title="$3,724"
              />
            </div>
            <div className="DebtCard3">
              <CardComponent
                imageClassName="CardImage funPurple"
                img={require('../images/credit_card_payment_icon.png')}
                title="$10,390"
              />
            </div>
            <div className="DebtCard4">
              <CardComponent
                imageClassName="CardImage funCyan"
                img={require('../images/home_icon.png')}
                title="$7,253"
              />
            </div>
          </div>
        </div>
      )
    } else {
      debtGroup = (
        <div className="DebtCards">
          <div className="DebtTextContainer">
            <h1>Crush your debt</h1>
            <h3>
              Negotiate and lower<br></br>your debt by up to 70%
            </h3>
          </div>

          <div className="DebtCard5">
            <CardComponent
              imageClassName="CardImage funLightBlue"
              img={require('../images/loans_icon.png')}
              title="$5,464"
            />
          </div>

          <div className="DebtCard6">
            <CardComponent
              imageClassName="CardImage funPink"
              img={require('../images/student_loans_icon.png')}
              title="$39,845"
            />
          </div>

          <div className="DebtCard7">
            <CardComponent
              imageClassName="CardImage funPink"
              img={require('../images/student_loans_icon.png')}
              title="$40,558"
            />
          </div>

          <div className="DebtCard1">
            <CardComponent
              imageClassName="CardImage funPurple"
              img={require('../images/credit_card_payment_icon.png')}
              title="$11,250"
            />
          </div>
          <div className="DebtCard2">
            <CardComponent
              imageClassName="CardImage funLightBlue"
              img={require('../images/loans_icon.png')}
              title="$3,724"
            />
          </div>
          <div className="DebtCard3">
            <CardComponent
              imageClassName="CardImage funPurple"
              img={require('../images/credit_card_payment_icon.png')}
              title="$10,390"
            />
          </div>
          <div className="DebtCard4">
            <CardComponent
              imageClassName="CardImage funCyan"
              img={require('../images/home_icon.png')}
              title="$7,253"
            />
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className="">
          <div className="Hero">
            <div className="HeroGroup">
              <div className="HeroLeftGroup">
                <img
                  className="Phone"
                  src={require('../images/hero-phone-2.png')}
                ></img>
              </div>

              {heroText}
            </div>
          </div>
          <div className="Debt">
            <div className="DebtGroup">
              <div>{debtGroup}</div>
            </div>
          </div>

          <div className="Advice">
            <div className="AdviceGroup">
              <div className="AdviceLeftGroup">
                <div>
                  <h1>
                    Get answers<br></br>anytime, anywhere
                  </h1>
                  <h3>
                    Clerkie gives you personalized<br></br>solutions and
                    real-time answers<br></br>for your budget, debt,<br></br>
                    credit and more
                  </h3>
                </div>
              </div>
              <div className="AdviceRightGroup">
                <div className="ChatBubbleContainer">
                  <div className="Bubble1">
                    <ChatBubble
                      className="UserChatBubbleText"
                      img={require('../images/profile-image-5.jpeg')}
                      textLine1="What’s the fastest way for me"
                      textLine2="to get out of debt?"
                    />
                  </div>

                  <div className="Bubble2">
                    <ChatBubble
                      className="ClerkieChatBubbleText"
                      img={require('../images/alex.jpg')}
                      textLine1="How do I improve my credit"
                      textLine2="to buy a house?"
                    />
                  </div>
                  <div className="Bubble3">
                    <ChatBubble
                      className="UserChatBubbleText"
                      img={require('../images/profile-image-6.jpeg')}
                      textLine1="Can you negotiate down my"
                      textLine2="medical debt?"
                    />
                  </div>
                  <div className="Bubble4">
                    <ChatBubble
                      className="ClerkieChatBubbleText"
                      img={require('../images/melinda.jpg')}
                      textLine1="Can you help me pay off my"
                      textLine2="student loans?"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Bills">
            <div className="BillsGroup">
              <div className="BillsLeftGroup">
                <div className="BillsSquareContainer">
                  <div className="BillsSquareBackgroundContainer">
                    <div className="BillsSquareTopLeftSection Background" />
                    <div className="BillsSquareSection Background" />
                    <div className="BillsSquareSection Background"></div>
                    <div className="BillsSquareSection Background"></div>
                    <div className="BillsSquareSection Background" />
                    <div className="BillsSquareSection Background"></div>
                    <div className="BillsSquareSection Background"></div>
                    <div className="BillsSquareSection Background"></div>
                  </div>
                  <div className="BillsSquareContentContainer">
                    <div className="BillsSquareTopLeftSection Content">
                      <p>Crush Your Debt</p>
                    </div>
                    <div className="BillsSquareSection Content">
                      <div className="BillsSquareTopSection">
                        <div className="BillsSquareSectionImage">
                          <img src={require('../images/car.png')}></img>
                        </div>
                      </div>
                      <div className="BillsSquareBottomSection">
                        <p>Car Loans</p>
                      </div>
                    </div>
                    <div className="BillsSquareSection Content">
                      <div className="BillsSquareTopSection">
                        <div className="BillsSquareSectionImage">
                          <img src={require('../images/cap.svg')}></img>
                        </div>
                      </div>
                      <div className="BillsSquareBottomSection">
                        <p>Student Loans</p>
                      </div>
                    </div>
                    <div className="BillsSquareSection Content">
                      <div className="BillsSquareTopSection">
                        <div className="BillsSquareSectionImage">
                          <img src={require('../images/credit_card.svg')}></img>
                        </div>
                      </div>
                      <div className="BillsSquareBottomSection">
                        <p>Credit Cards</p>
                      </div>
                    </div>
                    <div className="BillsSquareSection Content">
                      <div className="BillsSquareTopSection">
                        <div className="BillsSquareSectionImage">
                          <img src={require('../images/price.png')}></img>
                        </div>
                      </div>
                      <div className="BillsSquareBottomSection">
                        <p>Medical Debt</p>
                      </div>
                    </div>
                    <div className="BillsSquareSection Content">
                      <div className="BillsSquareTopSection">
                        <div className="BillsSquareSectionImage">
                          <img src={require('../images/moneybag.png')}></img>
                        </div>
                      </div>
                      <div className="BillsSquareBottomSection">
                        <p>Personal Loans</p>
                      </div>
                    </div>
                    <div className="BillsSquareSection Content">
                      <div className="BillsSquareTopSection">
                        <div className="BillsSquareSectionImage">
                          <img src={require('../images/bars.png')}></img>
                        </div>
                      </div>
                      <div className="BillsSquareBottomSection">
                        <p>Repair Credit</p>
                      </div>
                    </div>
                    <div className="BillsSquareSection Content">
                      <div className="BillsSquareTopSection">
                        <div className="BillsSquareSectionImage">
                          <img src="https://clerkie-assets.s3-us-west-2.amazonaws.com/app-assets/save_split_goals/icon_rocket_white.png"></img>
                        </div>
                      </div>
                      <div className="BillsSquareBottomSection">
                        <p>Boost Score</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="BillsRightGroup">
                <h1>
                  Easily manage<br></br>your bills
                </h1>
                <h3>
                  Build your credit score and<br></br>pay all your bills in one
                  place
                </h3>
              </div>
            </div>
          </div>
          <div className="Loans">
            <div className="LoansGroup">
              <div className="LoansTitle">{loansHeader}</div>
              <div className="LoansLeftGroup">
                <img src={require('../images/notification_image_2.jpg')}></img>
              </div>
              <div className="LoansRightGroup">
                <h3 className="LoansDesktopText">
                  Borrow money when<br></br>times are tough to cover<br></br>
                  your monthly expenses
                </h3>
                <h3 className="LoansMobileText">
                  Borrow money when times are tough<br></br>to cover your
                  monthly expenses
                </h3>
              </div>
            </div>
          </div>
          <div className="GetClerkie">
            <div className="GetClerkieGroup">
              <div className="GetClerkieTitle">
                <h1>Try it out!</h1>
              </div>
              <div className="BlankSpace15Percent"></div>
              <div className="GetClerkieButtons">
                <div className="ButtonsLeftGroup">
                  <a onClick={event => this.androidClicked()}>
                    <img src={require('../images/play_store.png')}></img>
                  </a>
                </div>
                <div className="ButtonsRightGroup">
                  <a onClick={event => this.appleClicked()}>
                    <img src={require('../images/app_store.svg')}></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FrontPage
