import React from 'react'
import "./ChatBubble.css"


class ChatBubble extends React.Component { 

    constructor(props) { 
      super(props); 

      this.state = { 
        hasScrolled: false
      } 
  
    }
  
    componentDidMount() { 
      window.addEventListener('scroll', this.handleScroll);
    }
  
    handleScroll = () => {
        const innerWidth = window.innerWidth;
        const isMobile = window.innerWidth <= 640;
        const heroHeight = isMobile ? innerWidth * 2 : innerWidth * 0.54;
        const headerHeight = isMobile ? 80 : 40 + (innerWidth * 0.0238);

        const scrollTop = window.pageYOffset;
  
        if (scrollTop > (heroHeight - headerHeight)) { 
        this.setState({ hasScrolled: true } );
        } else { 
        this.setState({ hasScrolled: false } );
        }
    };
  
    render() { 

      return ( 
        <div className = {this.state.hasScrolled ? "ChatBubble" : "ChatBubble"}>
            <img className="ChatBubbleImage" src={this.props.img}></img>
            <p className={this.props.className}>
                {this.props.textLine1}<br></br>
                {this.props.boldText}
                {this.props.textLine2}
            </p>
        </div>
      )
    }
  }
  
  export default ChatBubble